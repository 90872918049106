import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../images/logo.svg';

const Slide2 = ({ data }) => (
  <div className="relative" style={{ height: `80vh` }}>
    <picture>
      <img
        sizes="(max-width: 3840px) 100vw, 3840px"
        srcSet="
            /images/hero-3/hero-3_u4shlc_c_scale,w_320.jpg 320w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_826.jpg 826w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_1156.jpg 1156w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_1429.jpg 1429w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_1674.jpg 1674w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_1888.jpg 1888w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_2093.jpg 2093w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_2284.jpg 2284w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_2454.jpg 2454w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_2644.jpg 2644w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_2802.jpg 2802w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_2954.jpg 2954w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_3103.jpg 3103w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_3255.jpg 3255w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_3399.jpg 3399w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_3533.jpg 3533w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_3668.jpg 3668w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_3807.jpg 3807w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_3824.jpg 3824w,
            /images/hero-3/hero-3_u4shlc_c_scale,w_3840.jpg 3840w"
        src="/images/hero-3/hero-3_u4shlc_c_scale,w_3840.jpg"
        className="absolute h-full inset-0 object-cover w-full"
        alt="Person camping under a starry sky."
      />
    </picture>
    <h1 className="absolute flex items-center justify-center font-display inset-0 italic text-6xl">
      <img
        src={Logo}
        alt={data.site.siteMetadata.title}
        className="mx-auto -mt-20 relative z-10"
        style={{ width: `75vw` }}
      />
    </h1>
    <picture>
      <img
        sizes="(max-width: 3840px) 100vw, 3840px"
        srcSet="
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_320.png 320w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_773.png 773w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_1087.png 1087w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_1353.png 1353w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_1592.png 1592w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_1797.png 1797w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_1988.png 1988w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_2177.png 2177w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_2350.png 2350w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_2509.png 2509w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_2665.png 2665w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_2821.png 2821w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_2972.png 2972w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_3105.png 3105w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_3244.png 3244w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_3392.png 3392w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_3525.png 3525w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_3657.png 3657w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_3785.png 3785w,
          /images/overlay-3/overlay-3_wnfoi4_c_scale,w_3840.png 3840w"
        src="/images/overlay-3/overlay-3_wnfoi4_c_scale,w_3840.png"
        className="absolute h-full inset-0 object-cover w-full z-20"
        alt=""
      />
    </picture>
  </div>
);

Slide2.propTypes = {
  data: PropTypes.object,
};

export default Slide2;
