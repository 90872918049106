import React, { Component } from 'react';
import ReactModal from 'react-modal';

import Form from './Form';

export default class Modal extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    ReactModal.setAppElement('#___gatsby');
    const { showModal } = this.state;
    return (
      <div
        className="absolute bottom-0 flex font-display inset-x-0 items-center justify-center pointer-events-auto z-50"
        style={{
          transform: `translateY(50%)`,
        }}
      >
        <div
          className="bg-white hidden sm:block px-12 py-3 text-black"
          style={{
            boxShadow: `0px 0px 5px 0px rgba(0,0,0,0.5), 6px 8px 10px 2px rgba(0,0,0,0.25)`,
            transform: `skew(-33deg)`,
          }}
        >
          <div className="truncate" style={{ transform: `skew(33deg)` }}>
            For all enquiries contact us today
          </div>
        </div>
        <button
          className="bg-black hover:bg-gray-900 px-12 py-4 text-white"
          style={{
            boxShadow: `0px 0px 5px 0px rgba(0,0,0,0.5), 6px 8px 10px 2px rgba(0,0,0,0.25)`,
            transform: `skew(-33deg)`,
          }}
          onClick={this.handleOpenModal}
          type="button"
        >
          <div style={{ transform: `skew(33deg)` }}>Email</div>
        </button>
        <ReactModal
          isOpen={showModal}
          contentLabel="Show contact form"
          onRequestClose={this.handleCloseModal}
          className="bg-transparent flex items-center justify-center min-h-screen"
          style={{
            overlay: {
              backgroundColor: 'hsla(0, 0%, 0%, 0.5)',
            },
          }}
        >
          <Form click={this.handleCloseModal} />
        </ReactModal>
      </div>
    );
  }
}
