import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../images/logo.svg';

const Slide2 = ({ data }) => (
  <div className="relative" style={{ height: `80vh` }}>
    <picture>
      <img
        sizes="(max-width: 3840px) 100vw, 3840px"
        srcSet="
            /images/hero-2/hero-2_s2hsxj_c_scale,w_320.jpg 320w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_791.jpg 791w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_1121.jpg 1121w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_1396.jpg 1396w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_1646.jpg 1646w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_1867.jpg 1867w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_2064.jpg 2064w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_2267.jpg 2267w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_2443.jpg 2443w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_2611.jpg 2611w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_2768.jpg 2768w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_2935.jpg 2935w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_3083.jpg 3083w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_3219.jpg 3219w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_3352.jpg 3352w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_3493.jpg 3493w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_3625.jpg 3625w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_3748.jpg 3748w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_3812.jpg 3812w,
            /images/hero-2/hero-2_s2hsxj_c_scale,w_3840.jpg 3840w"
        src="/images/hero-2/hero-2_s2hsxj_c_scale,w_3840.jpg"
        className="absolute h-full inset-0 object-cover w-full"
        alt="Man standing in river with fishing rod."
      />
    </picture>
    <h1 className="absolute flex items-center justify-center font-display inset-0 italic text-6xl">
      <img
        src={Logo}
        alt={data.site.siteMetadata.title}
        className="mx-auto -mt-20 relative z-10"
        style={{ width: `75vw` }}
      />
    </h1>
    <picture>
      <img
        sizes="(max-width: 3840px) 100vw, 3840px"
        srcSet="
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_320.png 320w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_892.png 892w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_1281.png 1281w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_1602.png 1602w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_1864.png 1864w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_2140.png 2140w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_2365.png 2365w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_2583.png 2583w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_2809.png 2809w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_3016.png 3016w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_3213.png 3213w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_3394.png 3394w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_3607.png 3607w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_3771.png 3771w,
          /images/overlay-2/overlay-2_ehjmer_c_scale,w_3840.png 3840w"
        src="/images/overlay-2/overlay-2_ehjmer_c_scale,w_3840.png"
        className="absolute h-full inset-0 object-cover w-full z-20"
        alt=""
      />
    </picture>
  </div>
);

Slide2.propTypes = {
  data: PropTypes.object,
};

export default Slide2;
