import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../images/logo.svg';

const Slide1 = ({ data }) => (
  <div className="relative" style={{ height: `80vh` }}>
    <picture>
      <img
        sizes="(max-width: 3840px) 100vw, 3840px"
        srcSet="
          /images/hero-1/hero-1_lbb5sn_c_scale,w_320.jpg 320w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_788.jpg 788w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_1110.jpg 1110w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_1377.jpg 1377w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_1614.jpg 1614w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_1830.jpg 1830w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_2034.jpg 2034w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_2226.jpg 2226w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_2405.jpg 2405w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_2583.jpg 2583w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_2753.jpg 2753w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_2907.jpg 2907w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_3063.jpg 3063w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_3218.jpg 3218w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_3364.jpg 3364w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_3504.jpg 3504w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_3649.jpg 3649w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_3791.jpg 3791w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_3818.jpg 3818w,
          /images/hero-1/hero-1_lbb5sn_c_scale,w_3840.jpg 3840w"
        src="/images/hero-1/hero-1_lbb5sn_c_scale,w_3840.jpg"
        className="absolute h-full inset-0 object-cover w-full"
        alt="Person on quad-bike going over a muddy jump"
      />
    </picture>
    <h1 className="absolute flex items-center justify-center font-display inset-0 italic text-6xl">
      <img
        src={Logo}
        alt={data.site.siteMetadata.title}
        className="mx-auto -mt-20 relative z-10"
        style={{ width: `75vw` }}
      />
    </h1>
    <picture>
      <img
        sizes="(max-width: 3840px) 100vw, 3840px"
        srcSet="
          /images/overlay-1/overlay-1_ro7542_c_scale,w_320.png 320w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_810.png 810w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_1145.png 1145w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_1422.png 1422w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_1674.png 1674w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_1886.png 1886w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_2098.png 2098w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_2274.png 2274w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_2443.png 2443w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_2611.png 2611w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_2770.png 2770w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_2906.png 2906w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_3052.png 3052w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_3199.png 3199w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_3337.png 3337w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_3470.png 3470w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_3602.png 3602w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_3732.png 3732w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_3794.png 3794w,
          /images/overlay-1/overlay-1_ro7542_c_scale,w_3840.png 3840w"
        src="/images/overlay-1/overlay-1_ro7542_c_scale,w_3840.png"
        className="absolute h-full inset-0 object-cover w-full z-20"
        alt=""
      />
    </picture>
  </div>
);

Slide1.propTypes = {
  data: PropTypes.object,
};

export default Slide1;
