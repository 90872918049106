import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`state`, `of`, `excitement`]} />
    <Hero />
  </Layout>
);

export default IndexPage;
