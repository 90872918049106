import React from 'react';
import Carousel from 'nuka-carousel';
import { graphql, Link, StaticQuery } from 'gatsby';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import Slide1 from './Slides/Slide1';
import Slide2 from './Slides/Slide2';
import Slide3 from './Slides/Slide3';
import Modal from './Modal';

import SOE from '../images/soe.svg';

const Hero = () => (
  <StaticQuery
    query={graphql`
      query HeroQuery {
        site {
          siteMetadata {
            title
            facebook
            instagram
          }
        }
      }
    `}
    render={data => (
      <div
        className="relative text-center text-white"
        style={{
          height: `80vh`,
          boxShadow: `0px 0px 5px 0px rgba(0,0,0,0.75), 0px 5px 10px 0px rgba(0,0,0,0.5)`,
        }}
      >
        <div className="relative z-10">
          <Carousel
            autoplay
            autoplayInterval={5000}
            className="bg-black"
            cellAlign="center"
            dragging={false}
            easing="easeCubicInOut"
            enableKeyboardControls
            style={{ display: `relative`, height: `80vh` }}
            swiping
            wrapAround
            renderCenterLeftControls={({ previousSlide }) => (
              <button
                aria-label="Previous slide"
                className="font-bold leading-none opacity-75 px-6 text-6xl"
                onClick={previousSlide}
                type="button"
              >
                <MdChevronLeft />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button
                aria-label="Next slide"
                className="font-bold leading-none opacity-75 px-6 text-6xl"
                style={{ bottom: 0, right: 0, top: 0 }}
                onClick={nextSlide}
                type="button"
              >
                <MdChevronRight />
              </button>
            )}
            renderBottomCenterControls={null}
          >
            <Slide1 data={data} />
            <Slide2 data={data} />
            <Slide3 data={data} />
          </Carousel>
          <div className="absolute flex flex-col inset-0 pointer-events-none">
            <div className="absolute flex font-display inset-x-0 justify-between px-6 py-4 top-0 z-10 pointer-events-auto">
              <Link to="/">
                <img className="w-12" src={SOE} alt="" />
              </Link>
              <div className="flex items-center">
                <a
                  href={data.site.siteMetadata.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className="ml-4" />
                </a>
                <a
                  href={data.site.siteMetadata.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className="ml-4" />
                </a>
                <span className="ml-4">Get in touch</span>
              </div>
            </div>
            <div className="absolute bottom-0 flex inset-x-0 justify-center mb-8 md:mb-16 pointer-events-auto px-4 text-sm sm:text-lg z-10">
              <h2 className="uppercase">
                What gets your{' '}
                <strong>
                  <em>adrenaline</em>
                </strong>{' '}
                pumping?
              </h2>
            </div>
            <Modal />
          </div>
        </div>
      </div>
    )}
  />
);

export default Hero;
